export default function ({ width = 14, height = 22, stroke = 'stroke-neutral-01' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 40">
        <path className={stroke} id="Vector" d="M8 1C7.20435 1 6.44129 1.31607 5.87868 1.87868C5.31607 2.44129 5 3.20435 5 4V12C5 12.7956 5.31607 13.5587 5.87868 14.1213C6.44129 14.6839 7.20435 15 8 15C8.79565 15 9.55871 14.6839 10.1213 14.1213C10.6839 13.5587 11 12.7956 11 12V4C11 3.20435 10.6839 2.44129 10.1213 1.87868C9.55871 1.31607 8.79565 1 8 1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

        <path className={stroke} id="Vector_2" d="M15 10V12C15 13.8565 14.2625 15.637 12.9497 16.9497C11.637 18.2625 9.85652 19 8 19C6.14348 19 4.36301 18.2625 3.05025 16.9497C1.7375 15.637 1 13.8565 1 12V10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

        <path className={stroke} id="Vector_3" d="M8 19V23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

        <path className={stroke} id="Vector_4" d="M4 23H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
