import { useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, redirect, getRouteApi } from '@tanstack/react-router'
import axios from 'axios'
import Lottie from 'lottie-react'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'

import { FeedbackSection } from '@/sections/feedback'
import { Feedback } from '@/types/feedback'
import { postUnscriptedAudio, questions } from '@api'
import ElevenlabsPlayer from '@components/ui/elevenlabs-player'
import AudioPlayer from '@components/AudioPlayer'
import Button from '@components/action/button'
import Select from '@components/action/select'
import { authSig } from '@/signals'
import HeaderSection from '@/sections/header'
import { PlusIcon, ChevronIcon, ArrowIcon } from '@icons'
import Heading from '@components/ui/heading'
import RecordButton from '@/components/action/record'
import loader from '@/assets/lottie/loader.json'
import { Rating } from '@/types/ratings.d'
import { ratingResults } from '@/sections/feedback/components/ratings'

const DEFAULT_THEME_ID = 1

const route = getRouteApi('/unscripted')

let selectedTheme = signal<{
  label: string
  value: number
} | null>(null)

const modes: {
  label: string
  value: string
  title: string
  description: string
  ratingsConfig: Rating.Config[]
  questions: boolean
}[] = [
  {
    label: 'Free Speech Analysis',
    value: 'free-speech',
    title: 'Share your thoughts on any topic or practice a section of your presentation.',
    description: 'Note: This demo allows up to 25 seconds of recording. Learn more in our product roadmap.',
    ratingsConfig: [
      {
        name: Rating.FEEDBACK_TYPE.PRONUNCIATION,
        type: Rating.RATING_TYPE.CEFR,
        color: 'purple',
        title: 'Pronunciation',
      },
      {
        name: Rating.FEEDBACK_TYPE.VOCABULARY,
        type: Rating.RATING_TYPE.CEFR,
        color: 'blue',
        title: 'Vocabulary',
      },
    ],
    questions: false,
  },
  {
    label: 'Answer question (CEFR)',
    value: 'cefr',
    title: 'Choose a question, answer it, and receive instant feedback!',
    description: 'Note: This demo allows up to 25 seconds of recording. Learn more in our product roadmap.',
    ratingsConfig: [
      {
        name: Rating.FEEDBACK_TYPE.PRONUNCIATION,
        type: Rating.RATING_TYPE.CEFR,
        color: 'purple',
        title: 'Pronunciation',
      },
      {
        name: Rating.FEEDBACK_TYPE.VOCABULARY,
        type: Rating.RATING_TYPE.CEFR,
        color: 'blue',
        title: 'Vocabulary',
      },
    ],
    questions: true,
  },
  {
    label: 'Answer a question (IELTS)',
    value: 'ielts',
    title: 'Practice answering an IELTS mock question in your own words to improve your score.',
    description: 'Note: This demo allows up to 25 seconds of recording. Learn more in our product roadmap.',
    ratingsConfig: [
      {
        name: Rating.FEEDBACK_TYPE.PRONUNCIATION,
        type: Rating.RATING_TYPE.IELTS,
        color: 'purple',
        title: 'Pronunciation',
      },
      {
        name: Rating.FEEDBACK_TYPE.VOCABULARY,
        type: Rating.RATING_TYPE.IELTS,
        color: 'blue',
        title: 'Vocabulary',
      },
      {
        name: Rating.FEEDBACK_TYPE.GRAMMAR,
        type: Rating.RATING_TYPE.IELTS,
        color: 'green',
        title: 'Grammar',
      },
      {
        name: Rating.FEEDBACK_TYPE.COHERENCE,
        type: Rating.RATING_TYPE.IELTS,
        color: 'orange',
        title: 'Coherence',
      },
    ],
    questions: true,
  },
]

export const Route = createFileRoute('/unscripted')({
  beforeLoad: () => {
    if (!authSig.isAuthenticated.value) {
      throw redirect({
        to: '/login',
      })
    }
  },
  component: UnscriptedPage,
})

const commonStyles = 'w-full h-full text-neutral-05 leading-8 text-xl px-1 py-2 border-neutral-02 border-2 rounded-xl focus:border-neutral-07 hover:border-neutral-0 resize-none'

function UnscriptedPage() {
  const { mode } = route.useSearch()

  const [isLoading, setIsLoading] = useState(false)
  const [feedback, setFeedback] = useState<Feedback.ApiResponse | null>(null)

  const _mode = useMemo(() => {
    return modes.find((m) => m.value === mode) ?? modes[0]
  }, [mode])

  return (
    <>
      <HeaderSection
        modes={modes}
        path="/unscripted"
      />

      <div className="flex flex-col lg:flex-row gap-12 mt-10">
        <div className="w-full lg:max-w-1/2">
          <InputSection
            setIsLoading={setIsLoading}
            setFeedback={setFeedback}
            isLoading={isLoading}
            mode={_mode}
          />

          <TranscriptionSection feedback={feedback} />
        </div>

        <FeedbackSection
          isLoading={isLoading}
          feedback={feedback}
          ratingsConfig={_mode.ratingsConfig}
        />
      </div>
    </>
  )
}

type InputSectionProps = {
  setIsLoading: (value: boolean) => void
  setFeedback: (value: Feedback.ApiResponse) => void
  isLoading: boolean
  mode: {
    label: string
    value: string
    title: string
    description: string
    ratingsConfig: Rating.Config[]
    questions: boolean
  }
}

function InputSection({ setIsLoading, setFeedback, isLoading, mode }: InputSectionProps) {
  const [blob, setBlob] = useState<Blob | null>(null)
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [svgFill, setSvgFill] = useState('fill-neutral-01')

  const mutation = useMutation({
    mutationFn: postUnscriptedAudio,
    onSuccess: ({ data }: { data: { data: Feedback.ApiResponse } }) => {
      setIsLoading(false)
      setFeedback(data.data)
    },
    onError: (e) => {
      console.error(e)
      setIsLoading(false)
    },
  })

  function onSubmit() {
    if (!blob) {
      return
    }

    ratingResults.value = {
      [Rating.FEEDBACK_TYPE.PRONUNCIATION]: null,
      [Rating.FEEDBACK_TYPE.VOCABULARY]: null,
      [Rating.FEEDBACK_TYPE.GRAMMAR]: null,
      [Rating.FEEDBACK_TYPE.COHERENCE]: null,
    }

    const formData = new FormData()

    formData.append('audio', blob)

    setIsLoading(true)
    mutation.mutate(formData)
  }

  function onAudioFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return
    }

    const fileUrl = URL.createObjectURL(e.target.files[0])

    onRecordSelect(fileUrl)
  }

  async function onRecordSelect(value: string) {
    try {
      const audio = await axios.get(value, {
        responseType: 'arraybuffer',
      })

      const blob = new Blob([audio.data], { type: 'audio/mp3' })

      setBlob(blob)
    } catch (e) {
      console.info(e)
    }
  }

  return (
    <section className="flex">
      <div className="flex flex-col flex-1">
        <Heading
          squareClasses="bg-secondary-05"
          title={mode.title}
          subtitle={mode.description}
        />

        {mode.questions && (
          <div className="flex flex-col gap-2 mb-12 mt-8">
            <ThemeSelection />
            <QuestionsSection />
          </div>
        )}

        <>
          <div className="flex gap-4 mt-8">
            <RecordButton
              setBlob={setBlob}
              setAudioUrl={setAudioUrl}
              setIsRecording={setIsRecording}
            />

            <Button
              intent="success"
              disabled={(!audioUrl && !blob) || isRecording || mutation.isPending}
              onMouseEnter={() => setSvgFill('fill-secondary-03')}
              onMouseLeave={() => setSvgFill('fill-neutral-01')}
              onClick={onSubmit}
            >
              <div className="flex gap-4 items-center transition duration-500">
                {isLoading ? (
                  <Lottie
                    animationData={loader}
                    loop={true}
                    style={{ width: 24, height: 24 }}
                  />
                ) : (
                  <>
                    <span className="font-bold">Submit</span>
                    <ArrowIcon fill={svgFill} />
                  </>
                )}
              </div>
            </Button>
          </div>

          <div className="mt-4">
            <LoadAudioFile onSelectedFile={onAudioFileSelected} />
          </div>
        </>
      </div>
    </section>
  )
}

function QuestionsSection() {
  useSignals()

  const { data } = useQuery({
    queryKey: ['unscriptedQuestions', selectedTheme.value?.value],
    queryFn: () => questions.getQuestionsByTheme(selectedTheme.value?.value ?? DEFAULT_THEME_ID),
  })

  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const buttonsStyles = 'transition-all duration-500 bg-gray-300 rounded-full p-2 disabled:opacity-50 hover:bg-gray-400 disabled:hover:bg-gray-300'

  if (!data) return null

  return (
    <div>
      <textarea
        className={commonStyles}
        value={data.data[selectedQuestion].text}
        readOnly
      />

      <div className="flex gap-4 mt-4">
        <button
          onClick={() => setSelectedQuestion(selectedQuestion - 1)}
          className={buttonsStyles}
          disabled={selectedQuestion === 0}
        >
          <ChevronIcon direction="left" />
        </button>
        <button
          onClick={() => setSelectedQuestion(selectedQuestion + 1)}
          className={buttonsStyles}
          disabled={selectedQuestion === data.data.length - 1}
        >
          <ChevronIcon direction="right" />
        </button>
      </div>
    </div>
  )
}

function ThemeSelection() {
  const { data } = useQuery({
    queryKey: ['getThemeList'],
    queryFn: () => questions.getThemes(),
  })

  const themes: { label: string; value: number }[] = useMemo(() => {
    if (!data?.data.length) return []

    return data.data.map((d: any) => {
      return {
        label: d.name,
        value: d.id,
      }
    })
  }, [data])

  function onThemeSelect(value: string | number) {
    selectedTheme.value = themes.find((t) => t.value === value) ?? null
  }

  return (
    <Select
      data={themes}
      selected={selectedTheme.value}
      onChange={onThemeSelect}
    />
  )
}

function TranscriptionSection({ feedback }: { feedback: Feedback.ApiResponse | null }) {
  if (!feedback) {
    return null
  }

  return (
    <div className="flex flex-col mt-4">
      <div className="py-8">
        <Separator />
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-lg font-bold">What you said</h3>
        <textarea
          className={commonStyles}
          value={feedback.metadata.baseContent}
          readOnly
        ></textarea>
      </div>

      <div className="flex gap-4 mt-4">
        <div>
          <ElevenlabsPlayer text={feedback.metadata.baseContent} />
        </div>

        <div>
          <AudioPlayer
            text="You said"
            audioSrc={`https://storage.googleapis.com/flwc-performance-tool/${feedback?.metadata?.audioPath}`}
          />
        </div>
      </div>
    </div>
  )
}

function Separator() {
  return <div className={'relative border border-neutral-03 after:content-[attr(after)] after:absolute after:w-fit after:left-1/2 after:-translate-y-1/2 after:top-1/2 after:-translate-x-1/2 after:block after:overflow-visible after:z-10 after:px-4 after:bg-white after:text-neutral-04 after:text-center'}></div>
}

function LoadAudioFile({ onSelectedFile }: { onSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
  const fileRef = useRef<HTMLInputElement>(null)

  function onSelectFile() {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  return (
    <>
      <Button
        type="subtle"
        onClick={onSelectFile}
      >
        <span>Select audio file</span>
        <PlusIcon />
      </Button>

      <input
        accept=".mp3, .wav"
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={onSelectedFile}
      />
    </>
  )
}
