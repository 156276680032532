function DownloadIcon({ width = 32, height = 32, color = 'white' }: { width?: number; height?: number; color?: string }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.835 10.1663C16.835 9.7061 16.4619 9.33301 16.0016 9.33301C15.5414 9.33301 15.1683 9.7061 15.1683 10.1663V16.9049L12.8408 14.5774C12.5154 14.252 11.9877 14.252 11.6623 14.5774C11.3369 14.9029 11.3369 15.4305 11.6623 15.7559L14.8231 18.9167C15.4739 19.5676 16.5292 19.5676 17.1801 18.9167L20.3393 15.7576C20.6647 15.4321 20.6647 14.9045 20.3393 14.5791C20.0138 14.2536 19.4862 14.2536 19.1607 14.5791L16.835 16.9048V10.1663Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 17.6666C9.79357 17.6666 10.1667 18.0397 10.1667 18.5V20.1666C10.1667 20.6269 10.5398 21 11 21H21C21.4602 21 21.8333 20.6269 21.8333 20.1666V18.5C21.8333 18.0397 22.2064 17.6666 22.6667 17.6666C23.1269 17.6666 23.5 18.0397 23.5 18.5V20.1666C23.5 21.5473 22.3807 22.6666 21 22.6666H11C9.61929 22.6666 8.5 21.5473 8.5 20.1666V18.5C8.5 18.0397 8.8731 17.6666 9.33333 17.6666Z" fill={color} />
      <path d="M1.33325 16C1.33325 7.90004 7.89992 1.33337 15.9999 1.33337C24.0999 1.33337 30.6666 7.90004 30.6666 16C30.6666 24.1 24.0999 30.6667 15.9999 30.6667C7.89992 30.6667 1.33325 24.1 1.33325 16ZM15.9999 3.33337C12.6405 3.33337 9.41869 4.66789 7.04323 7.04335C4.66777 9.41881 3.33325 12.6406 3.33325 16C3.33325 19.3594 4.66777 22.5813 7.04323 24.9567C9.41869 27.3322 12.6405 28.6667 15.9999 28.6667C19.3593 28.6667 22.5811 27.3322 24.9566 24.9567C27.3321 22.5813 28.6666 19.3594 28.6666 16C28.6666 12.6406 27.3321 9.41881 24.9566 7.04335C22.5811 4.66789 19.3593 3.33337 15.9999 3.33337Z" fill={color} />
    </svg>
  )
}

export default DownloadIcon
