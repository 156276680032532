import { useEffect, useMemo, useState } from 'react'

import { ChevronIcon } from '@icons'

export type ItemData = {
  label: string
  value: string | number
} & Record<string, any>

type props = {
  data: ItemData[]
  onChange: (value: string | number) => void
  component?: (data: ItemData) => JSX.Element | null
  selected?: ItemData | null
}

function Select({ data, onChange, component, selected }: props) {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(selected ?? data[0])

  const buttonClasses = useMemo(() => {
    if (isOpen) return 'rounded-b-0'

    return 'rounded-b-xl'
  }, [isOpen])

  useEffect(() => {
    if (data.length) {
      setValue(selected ?? data[0])
    }
  }, [data])

  function handleSelect(value: ItemData) {
    setValue(value)
    onChange(value.value)
    setIsOpen(false)
  }

  if (!data.length) return <></>

  const list = data.map((value) => {
    if (component) {
      return component({ ...value, onClick: () => handleSelect(value) })
    }

    return (
      <a
        className="block p-3 cursor-pointer text-left hover:bg-neutral-03 border-t-2 border-neutral-03 first:border-t-0"
        key={value.value}
        onClick={() => handleSelect(value)}
      >
        {value.label}
      </a>
    )
  })

  return (
    <div className={`rounded-t-xl border-2 border-neutral-03 cursor-pointer w-full relative ${buttonClasses}`}>
      <button
        className="flex gap-8 items-center p-3 justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <label className="text-neutral-07 font-bold cursor-pointer text-left text-ellipsis overflow-hidden whitespace-nowrap">{value?.label}</label>
        <ChevronIcon />
      </button>

      <ul
        className="transition-all duration-500 border-2 opacity-0 open:opacity-100 rounded-b-xl border-neutral-03 absolute top-full left-0 right-0 bg-white z-10 flex flex-col max-h-0 open:max-h-64 overflow-y-auto"
        is-open={isOpen.toString()}
      >
        {list}
      </ul>
    </div>
  )
}

export default Select
