type props = {
  title: string
  squareClasses: string
  subtitle?: string
}

function Heading({ title, squareClasses, subtitle }: props) {
  return (
    <>
      <h2 className="flex gap-4 leading-8 text-neutral-07 font-bold text-m">
        <div className={`rounded px-2 ${squareClasses}`}></div>
        {title}
      </h2>
      {subtitle && <p className="mt-2 text-neutral-shades-04">{subtitle}</p>}
    </>
  )
}

export default Heading
