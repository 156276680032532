import { useEffect, useState, useRef, useMemo } from 'react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { DateTime } from 'luxon'

import { MicIcon, ArrowIcon, PlusIcon, PlayIcon, ChevronIcon } from '@icons'
import { useAudioRecorder, useAudioPlayer, useDebounce, useAuth } from '@hooks'

import ContentInput from '@components/form/content-input'
import { Feedback } from '@/types/feedback'
import Select, { type ItemData } from '@components/action/select'
import { Audios } from '@/types/audios'
import Heading from '@components/ui/heading'
import { getAudios, postAudio } from '@api'
import Button from '@components/action/button'
import ElevenlabsPlayer from '@/components/ui/elevenlabs-player'

import { analysisSig } from '@signals'
import AudioPlayer from '@/components/AudioPlayer'
import Lottie from 'lottie-react'
import loader from '@/assets/lottie/loader.json'

const route = getRouteApi('/')

const modes = ['word', 'sentence', 'text']

function UserInputSection({ setFeedback, setAudioUrl, setIsLoading, isLoading }: { isLoading: boolean; setFeedback: React.Dispatch<React.SetStateAction<Feedback.ApiResponse | null>>; setIsLoading: React.Dispatch<React.SetStateAction<boolean>>; setAudioUrl: (audioUrl: string) => void }) {
  const { mode } = route.useSearch()
  const [content, setContent] = useState('international')
  const [previousRecords, setPreviousRecords] = useState<Audios.FormattedData[]>([])
  const [blob, setBlob] = useState<Blob | null>(null)
  const [svgStroke, setSvgStroke] = useState('stroke-neutral-01')
  const [svgFill, setSvgFill] = useState('fill-neutral-01')
  const [fileLoaded, setFileLoaded] = useState(false)

  const { deleteApiKey } = useAuth()
  const navigate = useNavigate()

  const { debounce } = useDebounce(() => {
    audiosMutation.mutate(content)
  }, 3000)

  const _mode = useMemo(() => {
    if (modes.includes(mode)) {
      return mode
    }

    return 'word'
  }, [mode])

  const { start, stop, audioUrl, audioBlob, isRecording, clear } = useAudioRecorder()

  const isWord = _mode === 'word'

  const mutation = useMutation({
    mutationFn: (body: FormData) => postAudio(body, isWord),
    onSuccess: ({ data }: { data: { data: Feedback.ApiResponse } }) => {
      setIsLoading(false)
      setFeedback(data.data)
    },
    onError: (e: any) => {
      if (e.response?.status === 403) {
        deleteApiKey()
        navigate({ to: '/login' })
        return
      }

      analysisSig.error.value = "We couldn't finish analyzing your voice. Please record again and make sure we can hear you clearly."
      setIsLoading(false)
    },
  })

  const title = useMemo(() => {
    return `Repeat the ${_mode}`
  }, [_mode])

  useEffect(() => {
    setPreviousRecords([])
    debounce()
  }, [content])

  const audiosMutation = useMutation({
    mutationFn: getAudios,
    onSuccess: ({ data }: { data: { data: Audios.GetApiResponse } }) => {
      if (data.data.length === 0) {
        setPreviousRecords([])
        return
      }

      const formatedData: Audios.FormattedData[] = data.data.map((audio) => {
        const dt = DateTime.fromISO(audio.created)

        const pathElements = audio.filePath.split('/')

        return {
          label: pathElements[pathElements.length - 1],
          value: `${import.meta.env.VITE_RECORDINGS_BUCKET}/${audio.filePath}`,
          audioUrl: `${import.meta.env.VITE_RECORDINGS_BUCKET}/${audio.filePath}`,
          date: dt.toLocaleString(DateTime.DATETIME_SHORT),
        }
      })

      setPreviousRecords([
        {
          label: 'Previous recordings',
          value: '',
          audioUrl: '',
          date: '',
        },
        ...formatedData,
      ])
    },

    onError: () => {
      setPreviousRecords([])
    },
  })

  useEffect(() => {
    setAudioUrl(audioUrl ?? '')
  }, [audioUrl, setAudioUrl])

  useEffect(() => {
    if (_mode === 'word') {
      setContent('international')
      return
    }

    if (_mode === 'sentence') {
      setContent('Taylor Swift is one of the best songwriters in the world today.')
      return
    }

    setContent('')
  }, [_mode])

  function onRecordingClick() {
    if (isRecording) {
      stop()

      return
    }

    start()
    setBlob(null)
  }

  function onSubmit() {
    analysisSig.error.value = null

    const blobToSend = audioBlob ?? blob

    if (!blobToSend) {
      return
    }

    setFeedback(null)

    const formData = new FormData()

    formData.append('audio', blobToSend)
    formData.append('content', content)

    if (!audioBlob && blob) {
      formData.append('isReused', 'true')
    }

    setIsLoading(true)
    mutation.mutate(formData)
  }

  async function onRecordSelect(value: string) {
    clear()

    try {
      const audio = await axios.get(value, {
        responseType: 'arraybuffer',
      })

      const blob = new Blob([audio.data], { type: 'audio/mp3' })

      setBlob(blob)
    } catch (e) {
      console.info(e)
    }
  }

  function onFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return
    }

    const reader = new FileReader()

    reader.onload = (e) => {
      const text = e.target?.result

      if (typeof text !== 'string') {
        return
      }

      setContent(text)
      setFileLoaded(true)
    }

    reader.readAsText(e.target.files[0])
  }

  function onAudioFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return
    }

    const fileUrl = URL.createObjectURL(e.target.files[0])

    onRecordSelect(fileUrl)
  }

  return (
    <section className="flex">
      <div className="flex flex-col flex-1">
        <Heading
          squareClasses="bg-secondary-04"
          title={title}
        />

        <>
          <div className="mt-6 flex gap-4">
            <ContentInput
              onChange={(e) => {
                setContent(e)
                setFeedback(null)
                clear()
                analysisSig.error.value = null
              }}
              value={content}
              type={_mode === 'text' ? 'textarea' : 'input'}
            />
          </div>

          <div className="mt-4">
            <LoadFileButton
              mode={_mode}
              onSelectedFile={onFileSelected}
              fileLoaded={fileLoaded}
            />
          </div>

          <div className="mt-4 flex gap-5">
            <div>
              <ElevenlabsPlayer text={content} />
            </div>

            <div>
              <AudioPlayer
                text="You said"
                audioSrc={audioUrl}
              />
            </div>
          </div>
        </>

        <div className="flex gap-4 mt-8">
          <Button
            classes={isRecording ? 'bg-purple-500 bg-opacity-50' : ''}
            onMouseEnter={() => setSvgStroke('stroke-neutral-07')}
            onMouseLeave={() => setSvgStroke('stroke-neutral-01')}
            onClick={onRecordingClick}
          >
            <div className="flex gap-2">
              <MicIcon stroke={svgStroke} />
              <span className="font-bold">{isRecording ? 'Click once to stop recording your voice' : 'Click once to record your voice'}</span>
            </div>
          </Button>

          <Button
            intent="success"
            disabled={content === '' || (!audioUrl && !blob) || isRecording || mutation.isPending}
            onMouseEnter={() => setSvgFill('fill-secondary-03')}
            onMouseLeave={() => setSvgFill('fill-neutral-01')}
            onClick={onSubmit}
          >
            <div className="flex gap-4 items-center transition duration-500">
              {isLoading ? (
                <Lottie
                  animationData={loader}
                  loop={true}
                  style={{ width: 24, height: 24 }}
                />
              ) : (
                <>
                  <span className="font-bold">Submit</span>
                  <ArrowIcon fill={svgFill} />
                </>
              )}
            </div>
          </Button>
        </div>

        <div className="mt-4">
          <LoadAudioFile onSelectedFile={onAudioFileSelected} />
        </div>

        <div className="mt-6">
          <Separator isVisible={previousRecords.length > 0} />

          <div className="flex gap-6 mt-6">
            <PreviousRecords
              mode={_mode}
              records={previousRecords}
              onChange={onRecordSelect}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

function Separator({ isVisible = true }: { isVisible?: boolean }) {
  if (!isVisible) {
    return null
  }

  return (
    <div
      after="or use a previous recording"
      className={'relative border border-neutral-03 after:content-[attr(after)] after:absolute after:w-fit after:left-1/2 after:-translate-y-1/2 after:top-1/2 after:-translate-x-1/2 after:block after:overflow-visible after:z-10 after:px-4 after:bg-white after:text-neutral-04 after:text-center'}
    ></div>
  )
}

function LoadFileButton({ mode, onSelectedFile, fileLoaded }: { mode: string; onSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void; fileLoaded: boolean }) {
  if (mode !== 'text') {
    return null
  }

  const fileRef = useRef<HTMLInputElement>(null)

  function onSelectFile() {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  return (
    <>
      <Button
        type="subtle"
        onClick={onSelectFile}
      >
        <span>Load content from file</span>
        {fileLoaded ? <ChevronIcon direction="down" /> : <PlusIcon />}
      </Button>

      <input
        accept=".txt"
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={onSelectedFile}
      />
    </>
  )
}

function LoadAudioFile({ onSelectedFile }: { onSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
  const fileRef = useRef<HTMLInputElement>(null)

  function onSelectFile() {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  return (
    <>
      <Button
        type="subtle"
        onClick={onSelectFile}
      >
        <span>Select audio file</span>
        <PlusIcon />
      </Button>

      <input
        accept=".mp3, .wav"
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={onSelectedFile}
      />
    </>
  )
}

function PreviousRecords({ records, mode, onChange }: { records: Audios.FormattedData[]; mode: string; onChange: (value: string) => void }) {
  if (records.length === 0 || mode === 'text') {
    return null
  }

  return (
    <Select
      component={ListItem}
      data={records}
      onChange={(e) => {
        onChange(e.toString())
      }}
    />
  )
}

function ListItem({ label, date, audioUrl, value, onClick }: ItemData & { onClick?: () => void }) {
  const audioPlayer = useAudioPlayer(audioUrl)

  if (value === '') {
    return null
  }

  return (
    <div
      className="flex gap-8 items-center p-3 hover:bg-neutral-50"
      key={label}
    >
      <button
        className="w-8 h-8 hover:scale-150 transition-all duration-500"
        onClick={() => audioPlayer.play()}
      >
        <PlayIcon color="#80CAFF" />
      </button>

      <a
        className="flex flex-col flex-1 w-1/2 text-left"
        onClick={() => onClick?.()}
      >
        <span>{date}</span>
        <label className="text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer">{label}</label>
      </a>

      <span className="self-start">{audioPlayer.duration}</span>
    </div>
  )
}

export default UserInputSection
