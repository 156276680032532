import { effect, batch } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { notificationSig } from '@signals'
import { PlusIcon } from '@icons'

let intentClasses = ''

function Notification() {
  useSignals()

  effect(() => {
    switch (notificationSig.intent.value) {
      case 'error':
        intentClasses = 'bg-red-500 border-red-500'
        break

      case 'default':
      default:
        intentClasses = 'bg-blue-500 border-blue-500'
    }

    if (notificationSig.duration.value) {
      setTimeout(close, notificationSig.duration.value)
    }
  })

  function close() {
    batch(() => {
      notificationSig.message.value = null
      notificationSig.duration.value = null
      notificationSig.intent.value = 'default'
    })
  }

  if (!notificationSig.message.value) return null

  return (
    <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 pl-4 pr-2 py-2 rounded-xl border-2 flex items-start gap-4 max-w-96 shadow-2xl ${intentClasses} bg-opacity-50`}>
      <div>{notificationSig.message}</div>

      <button onClick={close}>
        <PlusIcon
          className="transform transition-opacity duration-500 rotate-45 fill-black opacity-50 hover:opacity-100"
          // DO NOT REMOVE. This allow to handle the color via classes
          color=""
        />
      </button>
    </div>
  )
}

export default Notification
