import { useState } from 'react'

import { createFileRoute, redirect } from '@tanstack/react-router'
import Heading from '@components/ui/heading'
import { TranslateModeForm } from '@components/form/TranslateModeForm'
import { postTranslateAudio } from '@api'
import { useMutation } from '@tanstack/react-query'
import { Translate } from '@/types/translate'
import { useAudioRecorder } from '@/hooks'
import { MicIcon, ArrowIcon } from '@icons'
import { authSig } from '@/signals'
import { TranslateResponseCard } from '@components/TranslateResponseCard'

export const Route = createFileRoute('/translate')({
  beforeLoad: () => {
    if (!authSig.isAuthenticated.value) {
      throw redirect({
        to: '/login',
      })
    }
  },
  component: TranslatePage,
})

function TranslatePage() {
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [translateResponse, setTranslateResponse] = useState<Translate.ApiResponse | null>(null)
  const [svgStroke, setSvgStroke] = useState('stroke-neutral-01')

  const { start, stop, audioUrl, audioBlob, isRecording } = useAudioRecorder()

  const translateMutation = useMutation({
    mutationFn: postTranslateAudio,
    onSuccess: ({ data }: { data: Translate.ApiResponse }) => {
      setTranslateResponse(data)
    },
    onError: (e) => {
      console.error(e)
    },
  })

  function onRecordingClick() {
    if (isRecording) {
      stop()

      return
    }

    start()
  }

  function onSubmit() {
    if (!audioBlob) {
      return
    }

    const formData = new FormData()

    formData.append('audio', audioBlob)
    formData.append('translate', selectedLanguage !== 'English' ? 'true' : 'false')

    translateMutation.mutate(formData)
  }

  return (
    <div className="flex flex-col lg:flex-row gap-12">
      <div className="flex-1">
        <Heading
          title="Choose a language"
          squareClasses="bg-secondary-04"
        />

        <TranslateModeForm
          selectedLanguage={selectedLanguage}
          onSelectLanguage={setSelectedLanguage}
        />

        <div className="flex gap-4 mt-8">
          <button
            className="bg-neutral-05 text-neutral-01 border-2 border-neutral-05 font-bold text-s px-4 rounded-xl flex gap-2 py-3 hover:text-neutral-05 hover:bg-neutral-01"
            onMouseEnter={() => setSvgStroke('stroke-neutral-07')}
            onMouseLeave={() => setSvgStroke('stroke-neutral-01')}
            onClick={onRecordingClick}
          >
            <MicIcon stroke={svgStroke} />
            <span className="font-bold">{isRecording ? 'Click once to stop recording your voice' : 'Click once to record your voice'}</span>
          </button>

          <button
            className="bg-secondary-03 text-neutral-01 text-s px-4 py-3 rounded-xl items-center flex gap-4 disabled:bg-opacity-40"
            disabled={!audioUrl || isRecording || translateMutation.isPending}
            onClick={onSubmit}
          >
            <span className="font-bold">Submit</span>
            <ArrowIcon />
          </button>
        </div>
      </div>

      <div className="flex-1">
        <TranslateResponseCard translateResponse={translateResponse} />
      </div>
    </div>
  )
}
