import { signal } from '@preact/signals-react'

export const analysisSig = {
  error: signal<string | null>(null),
}

export const authSig = {
  error: signal<string | null>(null),
  isAuthenticated: signal(false),
}

export const notificationSig = {
  message: signal<string | null>(null),
  duration: signal<number | null>(null),
  intent: signal('default'),
}
