// import { Link } from '@tanstack/react-router'
import { useMemo } from 'react'

export type ButtonProps = {
  to?: string
  text?: string
  disabled?: boolean
  type?: 'primary' | 'subtle' | 'outline'
  classes?: string
  children?: React.ReactNode
  intent?: 'warn' | 'success' | 'error' | 'default'
  size?: 'xs' | 's' | 'm' | 'l'
  icon?: JSX.Element | undefined
  borderRadius?: string

  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

function Button({
  // to,
  type = 'primary',
  classes,
  children,
  text = '',
  disabled = false,
  intent = 'default',
  size = 'm',
  borderRadius = 'rounded-xl',

  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}: ButtonProps) {
  const computedClasses = useMemo(() => {
    let c = 'disabled:opacity-40 transition duration-500 border-2 enabled:cursor-pointer flex gap-4 items-center justify-center'

    c = getSizeClasses(size, c)
    c = getTypeClasses(type, intent, c)

    return c
  }, [type, intent, size])

  return (
    <button
      className={`${computedClasses} ${classes} ${borderRadius}`}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children ? children : text}
    </button>
  )
}

function getSizeClasses(size: 'xs' | 's' | 'm' | 'l', c: string) {
  switch (size) {
    case 'xs':
      return `${c} px-2`

    case 's':
      return `${c} px-3 py-1 text-sm`

    // case 'l':

    case 'm':
    default:
      return `${c} px-4 py-3`
  }
}

function getTypeClasses(type: 'primary' | 'subtle' | 'outline', intent: 'warn' | 'success' | 'error' | 'default', c: string) {
  if (type === 'subtle') {
    return `${c} text-neutral-05 border-neutral-02 focus:border-neutral-07 hover:border-neutral-07`
  }

  if (intent === 'success') {
    return `${c} bg-secondary-03 text-neutral-01 border-secondary-03 enabled:hover:text-secondary-03 enabled:hover:bg-neutral-01`
  }

  return `${c} bg-neutral-05 text-neutral-01 border-neutral-05 enabled:hover:text-neutral-05 enabled:hover:bg-neutral-01`
}

export default Button
