import { useEffect } from 'react'
import { useAudioPlayer } from '@hooks'
import { PlayIcon, PauseIcon } from '@icons'
import AudioProgress from '@components/ui/audio-progress'

interface Props {
  audioSrc: string | null
  text: string
  range?: { start: number; end: number }
}

function AudioPlayer({ audioSrc, text, range }: Props) {
  const { play, pause, duration, isPlaying, progress, setAudioUrl } = useAudioPlayer(undefined, range)

  useEffect(() => {
    setAudioUrl(audioSrc)
  }, [audioSrc])

  function onClick() {
    if (isPlaying) {
      pause()
    } else {
      play()
    }
  }

  if (!audioSrc) return null

  return (
    <>
      <div className="flex gap-2 relative items-center h-9">
        <span className="text-lg">{text}</span>
      </div>

      <button
        className="p-2 rounded-2xl bg-[#894EE2] text-white flex mt-2 items-end"
        onClick={onClick}
      >
        <Icon isPlaying={isPlaying} />

        <div className="flex flex-col justify-between items-start mx-2 w-10 h-9">
          <AudioProgress progress={progress} />
          <span className="text-white text-xs">{duration}</span>
        </div>
      </button>
    </>
  )
}

function Icon({ isPlaying }: { isPlaying: boolean }) {
  if (isPlaying) {
    return (
      <PauseIcon
        width={36}
        height={36}
      />
    )
  }

  return (
    <PlayIcon
      width={36}
      height={36}
    />
  )
}

export default AudioPlayer
