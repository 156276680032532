import { useState } from 'react'
import Button from '@/components/action/button'
import Tag from '@/components/ui/tag'
import { PlusIcon, FlagIcon, MicIcon, CurlyIcon } from '@icons'
import PracticeModal from '@/components/practice-modal'
import { Feedback } from '@/types/feedback.d'

interface Props {
  title: string
  description: string
  status: 'success' | 'failure'
  tags?: {
    slug: string
    text: string
    color: 'red' | 'green' | 'orange'
    visible: boolean
  }[]
  metadata?: any
  word?: Feedback.Word
}

function FeedbackCard({ title, description, status, tags = [], metadata, word }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false)
  const [svgStroke, setSvgStroke] = useState('stroke-neutral-01')

  return (
    <div className="peer feedback-card peer-[.feedback-card]:border-t pt-4 flex gap-3 items-start">
      <FlagIcon status={status} />

      <div>
        <div className="flex gap-1 items-center">
          <span className="font-bold text-lg underline text-neutral-shades-04">{title}</span>

          <div className="rounded-full bg-neutral-04 w-1 h-1"></div>

          {tags.map((t, i) => {
            if (!t.visible) return null

            return (
              <Tag
                text={t.text}
                color={t.color}
                key={`tag_${t.text}_${i}`}
              />
            )
          })}
        </div>

        <div className="text-neutral-05 text-sm font-medium my-1">{description}</div>

        {!!word && (
          <Button
            onClick={() => setIsModalOpen(true)}
            text="Practice"
            size="s"
            borderRadius="rounded-full"
            onMouseEnter={() => setSvgStroke('stroke-neutral-07')}
            onMouseLeave={() => setSvgStroke('stroke-neutral-01')}
          >
            <div className="flex gap-2">
              <span>Practice</span>
              <MicIcon
                width={15}
                height={15}
                stroke={svgStroke}
              />
            </div>
          </Button>
        )}
      </div>

      {metadata && (
        <div className="flex-1 flex justify-end">
          <Button
            type="subtle"
            size="s"
            onClick={() => setIsJsonModalOpen(true)}
          >
            <CurlyIcon />
          </Button>
        </div>
      )}

      {!!word && (
        <PracticeModal
          visible={isModalOpen}
          word={word}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      {!!metadata && (
        <JsonModal
          visible={isJsonModalOpen}
          data={JSON.stringify(word, null, 4)}
          onClose={() => setIsJsonModalOpen(false)}
        />
      )}
    </div>
  )
}

function JsonModal({ visible, data, onClose }: { visible: boolean; data: string; onClose: () => void }) {
  if (!visible) {
    return null
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-neutral-07 bg-opacity-50">
      <div className="relative bg-white max-w-3xl w-full p-6 rounded-2xl">
        <header>
          <h3 className="text-neutral-07 text-3xl font-bold">JSON output</h3>
        </header>

        <div className="mt-3">
          <pre className="overflow-auto max-h-96 bg-neutral-03 p-4 rounded-xl">{data}</pre>
        </div>

        <button
          onClick={onClose}
          className="absolute right-0 bottom-full mb-2 lg:top-0 lg:left-full lg:ml-2 rounded-full w-9 h-9 bg-white flex items-center justify-center"
        >
          <PlusIcon asClose />
        </button>
      </div>
    </div>
  )
}

export default FeedbackCard
