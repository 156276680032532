import { useSignals } from '@preact/signals-react/runtime'

import { Rating } from '@/types/ratings'

import Card from './card'
import { FeedbackList } from './feedbackList'
import { ratingResults, selectedTile } from './ratings'

export function RatingList() {
  useSignals()

  const selectedResults = ratingResults.value[selectedTile.value] as Rating.RatingResponse

  return (
    <>
      {selectedResults?.recast && (
        <Card
          title="Here's a better way to say it:"
          description={`"${selectedResults.recast}"`}
          status="success"
        />
      )}

      <FeedbackList
        data={selectedResults.detailedFeedback || []}
        tags={['category']}
        revealStep={3}
        showPractice={false}
      />
    </>
  )
}
