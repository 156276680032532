import { useState } from 'react'

export function useAudioRecorder() {
  const audioChunks: Blob[] = []

  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)

  async function start() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const recorder = new MediaRecorder(stream)

    if (!recorder) {
      return
    }

    recorder.addEventListener('dataavailable', storeAudio)
    recorder.addEventListener('stop', () => saveAudio(stream))

    recorder.start()
    setIsRecording(true)
    setMediaRecorder(recorder)
  }

  function stop() {
    if (!mediaRecorder) {
      return
    }

    mediaRecorder.stop()
  }

  function storeAudio(e: BlobEvent) {
    audioChunks.push(e.data)
  }

  function saveAudio(stream: MediaStream) {
    const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' })
    const audioUrl = URL.createObjectURL(audioBlob)

    stream.getTracks().forEach((track) => track.stop())

    mediaRecorder?.removeEventListener('stop', () => saveAudio(stream))
    mediaRecorder?.removeEventListener('dataavailable', storeAudio)

    setAudioUrl(audioUrl)
    setAudioBlob(audioBlob)
    setIsRecording(false)
  }

  function clear() {
    setAudioUrl(null)
    setAudioBlob(null)
    setIsRecording(false)
  }

  return {
    audioUrl,
    audioBlob,
    isRecording,
    start,
    stop,
    pause: () => {},
    resume: () => {},
    clear,
  }
}
