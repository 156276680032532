import { useState } from 'react'
import axios from 'axios'
import { ElevenLabs } from '@/types/elevenlabs'

const http = axios.create({
  baseURL: 'https://api.elevenlabs.io/v1',
  headers: {
    'xi-api-key': import.meta.env.VITE_ELEVENLABS_API_KEY,
  },
})

export function useElevenLabs(voiceId: string = 'RPEIZnKMqlQiZyZd1Dae') {
  const [selectedVoice, setSelectedVoice] = useState(voiceId)

  async function generateAudioFromText(text: string): Promise<
    [
      {
        blob: Blob
        url: string
      } | null,
      any,
    ]
  > {
    const options = {
      method: 'POST',
      headers: {
        responseType: 'arraybuffer',
        'xi-api-key': import.meta.env.VITE_ELEVENLABS_API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text,
      }),
    }

    try {
      const res = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice}`,
        options,
      )

      const arrayBuffer = await res.arrayBuffer()
      const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' })
      const url = URL.createObjectURL(blob)

      return [
        {
          blob,
          url,
        },
        null,
      ]
    } catch (e) {
      return [null, e]
    }
  }

  async function getLastGeneratedAudioForText(
    text: string,
  ): Promise<[ElevenLabs.HistoryItem | null, any]> {
    try {
      const { data }: { data: ElevenLabs.GeneratedAudiosApi } = await http.get(
        `/history?voice_id=${selectedVoice}`,
      )

      const items = data.history.filter(
        (item) => item.text.toLocaleLowerCase() === text.toLocaleLowerCase(),
      )

      return [items[0] ?? null, null]
    } catch (e) {
      return [null, e]
    }
  }

  async function getAudioForHistoryItemId(historyItemId: string): Promise<
    [
      {
        blob: Blob
        url: string
      } | null,
      any,
    ]
  > {
    try {
      const audio = await http.get(`/history/${historyItemId}/audio`, {
        responseType: 'arraybuffer',
      })

      const blob = new Blob([audio.data], { type: 'audio/mp3' })
      const url = URL.createObjectURL(blob)

      return [
        {
          blob,
          url,
        },
        null,
      ]
    } catch (e) {
      return [null, e]
    }
  }

  return {
    selectedVoice,
    setSelectedVoice,
    generateAudioFromText,
    getLastGeneratedAudioForText,
    getAudioForHistoryItemId,
  }
}
