import { http } from './connector'

export function getThemes() {
  return http.get('/performance-tool/questions/themes')
}

export function getQuestionsByTheme(themeId: number) {
  return http.get('/performance-tool/open-questions', {
    params: { themeId },
  })
}
