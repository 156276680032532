import React, { useState } from 'react'
import { createFileRoute, useNavigate, redirect } from '@tanstack/react-router'
import { useAuth } from '@hooks'
import { authSig } from '@/signals'

export const Route = createFileRoute('/login')({
  beforeLoad: () => {
    if (authSig.isAuthenticated.value) {
      redirect({
        to: '/',
        throw: true,
      })
    }
  },
  component: Login,
})

function Login() {
  const { storeApiKey } = useAuth()
  const navigate = useNavigate()

  const [apiKey, setApiKey] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(e.target.value)
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    await storeApiKey(apiKey)
    navigate({ to: '/' })
  }

  return (
    <div className="h-full flex items-center justify-center">
      <div className="p-8 rounded-xl shadow-xl w-96">
        <h1 className="text-3xl mb-4">Login with an API key</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block mb-2"
              htmlFor="apiKey"
            >
              Your API key
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded"
              type="password"
              id="apiKey"
              value={apiKey}
              onChange={handleChange}
              required
            />
          </div>
          <button
            className="w-full bg-green-500 text-black py-2 rounded"
            type="submit"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  )
}
