function PauseIcon({ color = 'white', width = 32, height = 32 }: { color?: string; width?: number; height?: number }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 9.33337C12.2064 9.33337 11.8333 9.70647 11.8333 10.1667V21.8334C11.8333 22.2936 12.2064 22.6667 12.6667 22.6667C13.1269 22.6667 13.5 22.2936 13.5 21.8334V10.1667C13.5 9.70647 13.1269 9.33337 12.6667 9.33337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3334 9.33337C18.8732 9.33337 18.5001 9.70647 18.5001 10.1667V21.8334C18.5001 22.2936 18.8732 22.6667 19.3334 22.6667C19.7937 22.6667 20.1667 22.2936 20.1667 21.8334V10.1667C20.1667 9.70647 19.7937 9.33337 19.3334 9.33337Z"
        fill={color}
      />
      <path
        d="M1.3335 16C1.3335 7.90004 7.90016 1.33337 16.0002 1.33337C24.1002 1.33337 30.6668 7.90004 30.6668 16C30.6668 24.1 24.1002 30.6667 16.0002 30.6667C7.90016 30.6667 1.3335 24.1 1.3335 16ZM16.0002 3.33337C12.6408 3.33337 9.41894 4.66789 7.04348 7.04335C4.66802 9.41881 3.3335 12.6406 3.3335 16C3.3335 19.3594 4.66802 22.5813 7.04348 24.9567C9.41894 27.3322 12.6408 28.6667 16.0002 28.6667C19.3596 28.6667 22.5814 27.3322 24.9568 24.9567C27.3323 22.5813 28.6668 19.3594 28.6668 16C28.6668 12.6406 27.3323 9.41881 24.9568 7.04335C22.5814 4.66789 19.3596 3.33337 16.0002 3.33337Z"
        fill={color}
      />
    </svg>
  )
}

export default PauseIcon
