import { Suspense, lazy } from 'react'
import {
  Outlet,
  createRootRouteWithContext,
  useRouter,
} from '@tanstack/react-router'

const TanStackRouterDevtools =
  import.meta.env.VITE_ENV === 'dev'
    ? lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )
    : () => null

import type { AuthContext } from '../auth'
import NavigationSection from '@/sections/navigation'

export interface MyRouterContext {
  auth: AuthContext
}

const ignoredRoutes = ['/login']

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: () => {
    const router = useRouter()
    const inIgnoredRoute = ignoredRoutes.includes(
      router.state.location.pathname,
    )

    return (
      <div className="flex flex-col gap-9 h-screen lg:flex-row">
        <div className="sticky lg:fixed top-0 left-0 bg-white lg:h-full z-20">
          <NavigationSection />
        </div>

        <div
          className={`flex-1 lg:container mx-4 lg:mx-auto py-6 ${!inIgnoredRoute && 'lg:pl-40'}`}
        >
          <Outlet />
        </div>

        <Suspense>
          <TanStackRouterDevtools
            position="bottom-right"
            initialIsOpen={false}
          />
        </Suspense>
      </div>
    )
  },
})
