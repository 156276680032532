import { Feedback } from './feedback.d'

export namespace Rating {
  export enum FEEDBACK_TYPE {
    PRONUNCIATION = 'pronunciation',
    VOCABULARY = 'vocabulary',
    GRAMMAR = 'grammar',
    COHERENCE = 'coherence',
  }

  export enum RATING_TYPE {
    CEFR = 'cefr',
    IELTS = 'ielts',
  }

  export type Config = {
    name: Rating.FEEDBACK_TYPE
    type: Rating.RATING_TYPE
    color: string
    title: string
  }

  export type cefrLevel = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2'

  export type ieltsLevel = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'

  export type PronunciationResponse = {
    type: Rating.RATING_TYPE
    skill: Rating.FEEDBACK_TYPE
    level: string
    summary: string[]
  }

  export type RatingResponse = {
    type: Rating.RATING_TYPE
    skill: Rating.FEEDBACK_TYPE
    level: string
    generalSummary: string[]
    summary: string[]
    recast?: string
    detailedFeedback: Feedback.Word[]
  }
}
