import { useState, useRef, useCallback } from 'react'
import { Link } from '@tanstack/react-router'
import { useRouter, useNavigate } from '@tanstack/react-router'
import { batch } from '@preact/signals-react'

import { useAuth, useClickOutside } from '@hooks'
import { ChevronIcon, MicFilledIcon, LogoutIcon, PenIcon } from '@icons'
import { ratingResults, selectedTile } from '@/sections/feedback/components/ratings'
import { Rating } from '@/types/ratings.d'

const logoUrl = new URL('/flowchase.svg', import.meta.url).href

const ignoredRoutes = ['/login']

function NavigationSection() {
  const router = useRouter()
  const location = router.state.location.pathname

  const [isOpen, setIsOpen] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
    }
    setIsOpen(false)
  }, [isOpen])

  useClickOutside(menuRef, handleClickOutside)

  const navigate = useNavigate()

  const { deleteApiKey } = useAuth()

  const [activeLink, setActiveLink] = useState<string | null>(location)

  function handleLogout() {
    deleteApiKey()
    navigate({ to: '/login' })
  }

  function clearRatingStatus() {
    batch(() => {
      ratingResults.value = {
        [Rating.FEEDBACK_TYPE.PRONUNCIATION]: null,
        [Rating.FEEDBACK_TYPE.VOCABULARY]: null,
        [Rating.FEEDBACK_TYPE.GRAMMAR]: null,
        [Rating.FEEDBACK_TYPE.COHERENCE]: null,
      }
      selectedTile.value = Rating.FEEDBACK_TYPE.PRONUNCIATION
    })
  }

  if (ignoredRoutes.includes(location)) {
    return <></>
  }

  return (
    <div
      ref={menuRef}
      className={`flex lg:flex-col items-center lg:h-full px-4 transition-all duration-500 lg:w-32 lg:open:w-80 border-r border-neutral-03 relative py-6`}
      is-open={isOpen.toString()}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer absolute right-0 translate-x-1/2 top-6 border rounded-full p-1 bg-white hidden lg:block"
      >
        <div>
          <ChevronIcon direction={isOpen ? 'left' : 'right'} />
        </div>
      </button>

      <div className="flex justify-center  pr-0 lg:pb-6 w-fit lg:w-full">
        <Link
          to="/"
          className="flex gap-2 items-center w-8 h-8"
        >
          <img
            className="w-full h-full"
            src={logoUrl}
          />
        </Link>
      </div>

      <nav className="w-full lg:h-full flex lg:flex-col lg:mt-12 ml-6 lg:ml-0 flex-between items-center">
        <div className="flex-1 flex lg:flex-col gap-4 w-full px-7">
          <Link
            to="/"
            onMouseEnter={() => setActiveLink('/')}
            onMouseLeave={() => setActiveLink(null)}
            onClick={() => clearRatingStatus()}
            className={`transition-all duration-500 w-full flex items-center justify-start rounded-full p-2 hover:bg-neutral-03 ${location === '/' && 'bg-neutral-03'}`}
          >
            <MicFilledIcon fill={activeLink === '/' || location === '/' ? '#140032' : undefined} />
            <span
              className={'transition-all duration-500 w-0 lg:open:w-full overflow-hidden lg:open:ml-2 whitespace-nowrap'}
              is-open={isOpen.toString()}
            >
              Scripted feedback
            </span>
          </Link>

          <Link
            to="/unscripted"
            onMouseEnter={() => setActiveLink('/unscripted')}
            onMouseLeave={() => setActiveLink(null)}
            onClick={() => clearRatingStatus()}
            className={`transition-all duration-500 w-full flex items-center justify-start rounded-full p-2 hover:bg-neutral-03 ${location === '/unscripted' && 'bg-neutral-03'}`}
          >
            <PenIcon fill={activeLink === '/unscripted' || location === '/unscripted' ? '#140032' : undefined} />
            <span
              className="transition-all duration-500 w-0 lg:open:w-full overflow-hidden lg:open:ml-2 whitespace-nowrap"
              is-open={isOpen.toString()}
            >
              Spontaneous feedback
            </span>
          </Link>
        </div>

        <div
          className="w-full px-7 flex justify-end"
          is-open={isOpen.toString()}
        >
          <button
            onClick={handleLogout}
            className="transition-all duration-500 lg:w-full flex items-center justify-start rounded-full p-2 hover:bg-neutral-03"
          >
            <LogoutIcon />
            <span
              className={'transition-all duration-500 w-0 lg:open:w-full overflow-hidden lg:open:ml-2 whitespace-nowrap text-left'}
              is-open={isOpen.toString()}
            >
              Log out
            </span>
          </button>
        </div>
      </nav>
    </div>
  )
}

export default NavigationSection
