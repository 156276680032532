import { Translate } from '@/types/translate'
import React, { useState, useEffect } from 'react'
import ContentInput from '@components/form/content-input'
import Heading from '@components/ui/heading'

interface Props {
  translateResponse: Translate.ApiResponse | null
}

export function TranslateResponseCard({ translateResponse }: Props): React.ReactElement {
  const [content, setContent] = useState(translateResponse?.data || '')

  useEffect(() => {
    setContent(translateResponse?.data ?? '')
  }, [translateResponse?.data])

  return (
    <div>
      <Heading title="Your text" squareClasses="bg-secondary-02" />

      <div className="mb-8 bg-white rounded-xl mt-6">
        <ContentInput onChange={(content) => setContent(content)} value={content} type="textarea" />
      </div>
    </div>
  )
}
