/* eslint-disable react-refresh/only-export-components */
export default function ({ type = 'input', value = '', onChange, placeholder = '' }: { type?: 'input' | 'textarea'; value?: string; onChange: (value: string) => void; placeholder?: string }) {
  const commonStyles = 'w-full h-full text-neutral-05 leading-8 text-xl px-1 py-2 border-neutral-02 border-2 rounded-xl focus:border-neutral-07 hover:border-neutral-07'

  if (type === 'textarea') {
    return (
      <textarea
        className={commonStyles}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={5}
        placeholder="Insert your text here"
      />
    )
  }

  return (
    <input
      type="text"
      className={commonStyles}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  )
}
