let debounceId: number | null = null

export function useDebounce(callback: () => void, delay: number) {
  function _debounce() {
    if (debounceId) {
      clearTimeout(debounceId)
    }

    debounceId = setTimeout(callback, delay)
  }

  function debounce() {
    _debounce()
  }

  return {
    debounce,
  }
}
