import axios from 'axios'
import { notificationSig } from '@signals'
import { batch } from '@preact/signals-react'

export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

http.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      batch(() => {
        notificationSig.message.value = 'Your are not connected to the internet. Check you connection and try again.'
        notificationSig.duration.value = null
        notificationSig.intent.value = 'error'
      })
    }

    if (error.response?.status === 403) {
      batch(() => {
        notificationSig.message.value = 'Your connection has been interrupted. Please reconnect.'
        notificationSig.duration.value = 5000
        notificationSig.intent.value = 'error'
      })
    }

    return Promise.reject(error)
  },
)
