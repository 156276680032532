interface Props {
  selectedLanguage: string
  onSelectLanguage: (language: string) => void
}

const LANGUAGES = ['English', 'Spanish', 'French', 'German']

export function TranslateModeForm({ selectedLanguage, onSelectLanguage }: Props) {
  return (
    <div className="mt-2 relative">
      <select
        value={selectedLanguage}
        onChange={(e) => onSelectLanguage(e.target.value)}
        className="
        appearance-none bg-transparent focus:border-0 px-4 py-3 my-4
        relative border-2 text-neutral-07 font-bold border-neutral-03 rounded-xl hover:border-neutral-07 cursor-pointer w-fit
        after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:right-4 after:block after:w-2 after:h-2 after:border-b-2 after:border-r-2 after:border-neutral-04 after:rotate-45
      "
      >
        <option value="" disabled>
          Select a language
        </option>
        {LANGUAGES.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
      <h2 className="flex gap-4 leading-8 text-neutral-07 font-bold text-m">
        <div className="rounded bg-secondary-04 px-2"></div>
        Speak in your chosen language
      </h2>
    </div>
  )
}
