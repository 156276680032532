interface Props {
  status: 'success' | 'failure'
}

export default function FlagIcon({ status }: Props) {
  const iconColor = status === 'failure' ? '#E45364' : '#4FCD43'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M1 0C1.55228 0 2 0.447715 2 1V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V1C0 0.447715 0.447715 0 1 0Z" fill={iconColor} />
      <path d="M0 2C0 1.44772 0.447715 1 1 1H7.02633C7.67123 1 8.3119 1.10397 8.9237 1.3079L10.0763 1.6921C10.6881 1.89603 11.3288 2 11.9737 2H15C16.6569 2 18 3.34315 18 5V11C18 12.6569 16.6569 14 15 14H11.9737C11.3288 14 10.6881 13.896 10.0763 13.6921L8.9237 13.3079C8.3119 13.104 7.67123 13 7.02633 13H1C0.447715 13 0 12.5523 0 12V2Z" fill={iconColor} />
    </svg>
  )
}
