import { useEffect, useState } from 'react'

import Button from '@components/action/button'
import { useAudioRecorder } from '@hooks'
import { MicIcon } from '@icons'

type Props = {
  setBlob: (value: Blob | null) => void
  setAudioUrl: (value: string | null) => void
  setIsRecording: (value: boolean) => void
}

function RecordButton({ setBlob, setAudioUrl, setIsRecording }: Props) {
  const [svgStroke, setSvgStroke] = useState('stroke-neutral-01')

  const { start, stop, audioUrl, audioBlob, isRecording } = useAudioRecorder()

  useEffect(() => {
    setAudioUrl(audioUrl)
    setBlob(audioBlob)
  }, [audioUrl, audioBlob])

  useEffect(() => {
    setIsRecording(isRecording)
  }, [isRecording])

  function onRecordingClick() {
    if (isRecording) {
      stop()

      return
    }

    start()
  }

  return (
    <Button
      classes={isRecording ? 'bg-purple-500 bg-opacity-50' : ''}
      onMouseEnter={() => setSvgStroke('stroke-neutral-07')}
      onMouseLeave={() => setSvgStroke('stroke-neutral-01')}
      onClick={onRecordingClick}
    >
      <div className="flex gap-2">
        <MicIcon stroke={svgStroke} />
        <span className="font-bold">{isRecording ? 'Click once to stop recording your voice' : 'Click once to record your voice'}</span>
      </div>
    </Button>
  )
}

export default RecordButton
