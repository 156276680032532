import EmilyConfused from '@/assets/img/emily-confused.png'
import EmilyClapping from '@/assets/img/emily-clapping.png'
import EmilyWinking from '@/assets/img/emily-winking.png'
import EmilySmiling from '@/assets/img/emily-smiling.png'

function Avatar({ variant }: { variant: number }) {
  let iconSrc: string

  switch (variant) {
    case 0:
      iconSrc = EmilyConfused
      break
    case 1:
      iconSrc = EmilyClapping
      break
    case 2:
      iconSrc = EmilyWinking
      break
    case 3:
      iconSrc = EmilySmiling
      break
    default:
      iconSrc = EmilySmiling
  }

  return (
    <div className="w-28 h-28">
      <img src={iconSrc} />
    </div>
  )
}

export default Avatar
