import React, { useEffect } from 'react'
import { http, ping, connector } from '@api'
import { authSig, notificationSig } from '@signals'
import { batch } from '@preact/signals-react'

export interface AuthContext {
  token: string | null
  storeApiKey: (key: string) => Promise<void>
  deleteApiKey: () => void
}

const AuthContext = React.createContext<AuthContext | null>(null)

const key = 'demonstrator.auth.token'

function getStoredToken() {
  return localStorage.getItem(key)
}

function setStoredToken(token: string | null) {
  authSig.isAuthenticated.value = !!token

  if (!!token) {
    localStorage.setItem(key, token)

    return
  }

  localStorage.removeItem(key)
}

async function pingApi(token: string | null): Promise<void> {
  try {
    await ping()
    setStoredToken(token)
  } catch (e: any) {
    batch(() => {
      notificationSig.message.value = e?.response?.data?.message ?? 'Failed to ping the server'
      notificationSig.duration.value = null
      notificationSig.intent.value = 'error'
    })

    setStoredToken(null)
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<string | null>(null)

  useEffect(() => {
    const t = getStoredToken()
    setToken(t)
    setStoredToken(t)
    http.defaults.headers.common['x-api-key'] = t
    connector.defaults.headers.common['x-api-key'] = t
  }, [])

  async function storeApiKey(token: string) {
    http.defaults.headers.common['x-api-key'] = token
    connector.defaults.headers.common['x-api-key'] = token

    await pingApi(token)

    setToken(token)
  }

  function deleteApiKey() {
    http.defaults.headers.common['x-api-key'] = null
    connector.defaults.headers.common['x-api-key'] = null

    setStoredToken(null)
    setToken(null)
  }

  return <AuthContext.Provider value={{ token, storeApiKey, deleteApiKey }}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
