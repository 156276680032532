import { Suspense, useEffect, useMemo, useState } from 'react'
import { Feedback } from '@/types/feedback.d'
import { rateResponse } from '@api'
import { ChevronIcon } from '@icons'
import { useSuspenseQuery } from '@tanstack/react-query'
import { signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'

import { Rating } from '@/types/ratings.d'

type Props = {
  feedback?: Feedback.ApiResponse
  config: Rating.Config[]
  content?: string
}

const progressFloors: Record<Rating.RATING_TYPE, Record<string, string>> = {
  [Rating.RATING_TYPE.CEFR]: {
    A1: 'before:w-2',
    A2: 'before:w-4',
    B1: 'before:w-6',
    B2: 'before:w-8',
    C1: 'before:w-10',
    C2: 'before:w-full',
  },
  [Rating.RATING_TYPE.IELTS]: {
    '1': 'before:w-1',
    '2': 'before:w-2.5',
    '3': 'before:w-4',
    '4': 'before:w-5',
    '5': 'before:w-7',
    '6': 'before:w-8',
    '7': 'before:w-9',
    '8': 'before:w-11',
    '9': 'before:w-full',
  },
}

export const selectedTile = signal<Rating.FEEDBACK_TYPE>(Rating.FEEDBACK_TYPE.PRONUNCIATION)

export const ratingResults = signal<{
  [Rating.FEEDBACK_TYPE.PRONUNCIATION]: Rating.PronunciationResponse | null
  [Rating.FEEDBACK_TYPE.VOCABULARY]: Rating.RatingResponse | null
  [Rating.FEEDBACK_TYPE.COHERENCE]: Rating.RatingResponse | null
  [Rating.FEEDBACK_TYPE.GRAMMAR]: Rating.RatingResponse | null
}>({
  [Rating.FEEDBACK_TYPE.PRONUNCIATION]: null,
  [Rating.FEEDBACK_TYPE.VOCABULARY]: null,
  [Rating.FEEDBACK_TYPE.GRAMMAR]: null,
  [Rating.FEEDBACK_TYPE.COHERENCE]: null,
})

function Ratings({ config, feedback, content = '' }: Props) {
  useSignals()

  const [detailsOpen, setDetailsOpen] = useState(false)

  return (
    <div>
      <div className="flex gap-4">
        {config.map((a, i) => (
          <Suspense
            key={i}
            fallback={
              <Placeholder
                color={a.color}
                title={a.title}
              />
            }
          >
            <Tile
              content={a.name === 'pronunciation' ? JSON.stringify(feedback) : content}
              color={a.color}
              title={a.title}
              type={a.type}
              skill={a.name}
            />
          </Suspense>
        ))}
      </div>

      {(ratingResults.value[selectedTile.value]?.summary.length || 0) > 0 && (
        <details className="mt-4 bg-neutral-03 p-2 rounded-lg">
          <summary
            onClick={() => setDetailsOpen(!detailsOpen)}
            className="list-none flex gap-2 items-center"
          >
            <span>Feedback Summary</span>
            <ChevronIcon direction={detailsOpen ? 'down' : 'right'} />
          </summary>
          <ul className="list-disc ml-4 flex flex-col gap-1 mt-2">
            {ratingResults.value[selectedTile.value]?.summary.map((msg: string, i: number) => (
              <li
                key={i}
                className="text-xs leading-4"
              >
                {msg}
              </li>
            ))}
          </ul>
        </details>
      )}
    </div>
  )
}

type TileProps = {
  content: string
  color: string
  title: string
  type: Rating.RATING_TYPE
  skill: Rating.FEEDBACK_TYPE
}

type ResponseType = {
  data: {
    data: {
      data: {
        level: Rating.cefrLevel | Rating.ieltsLevel
        type: Rating.RATING_TYPE
        skill: Rating.FEEDBACK_TYPE
        summary?: string[]
        generalSummary?: string[]
      }
    }
  }
}

function Tile({ content, color, title, type, skill }: TileProps) {
  useSignals()

  const { data }: ResponseType = useSuspenseQuery({
    queryKey: ['rateResponse', { type, skill, content }],
    queryFn: () => rateResponse({ content, type, skill }),
  })

  const colorClasses = useMemo(() => {
    return selectColor(color)
  }, [color])

  useEffect(() => {
    const rating = data.data.data

    if (rating.generalSummary) {
      rating.summary = rating.generalSummary
    }

    ratingResults.value = {
      ...ratingResults.value,
      [rating.skill]: rating,
    }
  }, [data])

  function onTileClick() {
    selectedTile.value = skill
  }

  return (
    <button
      onClick={onTileClick}
      className={`px-4 py-2 border-2 rounded-lg ${colorClasses} transform duration-500`}
      is-selected={(selectedTile.value === skill).toString()}
    >
      <div className="text-xs text-neutral-shades-05 leading-none text-center">{title}</div>
      <div className="mt-1 flex flex-col items-center">
        <span className={`text-sm font-bold leading-none ${colorClasses}`}>{data.data.data.level}</span>

        <div
          is-selected="true"
          className={`
          w-12 h-1 rounded border ${colorClasses} relative
          before:content-[''] before:absolute before:left-0 ${progressFloors[type][data.data.data.level]} before:h-full before:rounded
        `}
        ></div>
      </div>
    </button>
  )
}

function Placeholder({ color, title }: { color: string; title: string }) {
  const colorClasses = useMemo(() => {
    return selectColor(color)
  }, [color])

  return (
    <div className="w-fit h-12 relative flex flex-col items-center justify-between">
      <svg
        height="54"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute"
      >
        <rect
          rx="8"
          ry="8"
          className={`${colorClasses} animate-border-loading`}
          height="100%"
          width="100%"
          strokeLinejoin="round"
          strokeWidth={2}
          strokeDasharray={50}
          fill="transparent"
        />
      </svg>

      <div className="text-xs px-4 py-2 text-neutral-shades-05 leading-none text-center">{title}</div>

      <div
        className={`w-12 h-1 rounded border ${colorClasses} relative overflow-hidden`}
        is-selected="true"
      >
        <svg
          height="54"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            rx="8"
            ry="8"
            className={`${colorClasses} animate-border-loading`}
            height="32"
            width="110%"
            strokeLinejoin="round"
            strokeWidth={4}
            strokeDasharray={75}
            fill="transparent"
          />
        </svg>
      </div>
    </div>
  )
}

function selectColor(color: string): string {
  switch (color) {
    case 'purple':
      return 'selected:border-ratings-purple hover:border-ratings-purple text-ratings-purple before:bg-ratings-purple stroke-ratings-purple'

    case 'blue':
      return 'selected:border-ratings-blue hover:border-ratings-blue text-ratings-blue before:bg-ratings-blue stroke-ratings-blue'

    case 'green':
      return 'selected:border-ratings-green hover:border-ratings-green text-ratings-green before:bg-ratings-green stroke-ratings-green'

    case 'orange':
      return 'selected:border-ratings-orange hover:border-ratings-orange text-ratings-orange before:bg-ratings-orange stroke-ratings-orange'

    default:
      return ''
  }
}

export default Ratings
