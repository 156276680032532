import { useState } from 'react'
import { batch } from '@preact/signals-react'

import { createFileRoute, redirect } from '@tanstack/react-router'

import UserInputSection from '@/sections/user-input'
import { FeedbackSection } from '@/sections/feedback'
import HeaderSection from '@/sections/header'
import { Feedback } from '@/types/feedback'
import { authSig } from '@/signals'
import { Rating } from '@/types/ratings.d'
import { ratingResults, selectedTile } from '@/sections/feedback/components/ratings'

const modes = [
  {
    label: 'Single word',
    value: 'word',
  },
  {
    label: 'Sentence',
    value: 'sentence',
  },
  {
    label: 'Small text',
    value: 'text',
  },
]
export const Route = createFileRoute('/')({
  beforeLoad: () => {
    if (!authSig.isAuthenticated.value) {
      throw redirect({
        to: '/login',
      })
    }
  },
  component: Index,
})

const ratingsConfig = [
  {
    name: Rating.FEEDBACK_TYPE.PRONUNCIATION,
    type: Rating.RATING_TYPE.CEFR,
    color: 'purple',
    title: 'CEFR Level',
  },
]

function Index() {
  const [feedback, setFeedback] = useState<Feedback.ApiResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [_audioUrl, setAudioUrl] = useState('')

  function onModeChange() {
    batch(() => {
      selectedTile.value = Rating.FEEDBACK_TYPE.PRONUNCIATION
      ratingResults.value = {
        [Rating.FEEDBACK_TYPE.PRONUNCIATION]: null,
        [Rating.FEEDBACK_TYPE.VOCABULARY]: null,
        [Rating.FEEDBACK_TYPE.GRAMMAR]: null,
        [Rating.FEEDBACK_TYPE.COHERENCE]: null,
      }
    })

    setFeedback(null)
    setAudioUrl('')
  }

  return (
    <div>
      <HeaderSection
        modes={modes}
        onChange={onModeChange}
        path="/"
      />

      <div className="flex flex-col lg:flex-row gap-12 mt-10">
        <div className="flex-1">
          <div>
            <UserInputSection
              isLoading={isLoading}
              setFeedback={setFeedback}
              setIsLoading={setIsLoading}
              setAudioUrl={setAudioUrl}
            />
          </div>
        </div>
        <div className="flex-1">
          <FeedbackSection
            isLoading={isLoading}
            feedback={feedback}
            ratingsConfig={ratingsConfig}
          />
        </div>
      </div>
    </div>
  )
}
