import { useMemo } from 'react'

type props = {
  text: string
  color?: 'red' | 'green' | 'orange'
}

function Tag({ text, color = 'green' }: props) {
  const colorClasses = useMemo(() => {
    if (color === 'red') {
      return 'bg-[#FFE2E7] text-[#E45364]'
    }

    if (color === 'orange') {
      return 'bg-[#FEECDC] text-[#E87F1E]'
    }

    return 'bg-[#D7FBD3] text-[#45C139]'
  }, [color])

  return <span className={`rounded-md px-1 font-bold text-xs leading-6 ${colorClasses}`}>{text}</span>
}

export default Tag
