import { useEffect, useState } from 'react'
import { useAudioPlayer, useElevenLabs } from '@hooks'
import { PlayIcon, PauseIcon, DownloadIcon } from '@icons'
import AudioProgress from '@components/ui/audio-progress'
import Lottie from 'lottie-react'
import loader from '../../assets/lottie/loader.json'

interface Props {
  text: string
  display?: 'default' | 'inline'
  forPracticeModal?: boolean
}

function ElevenlabsPlayer({
  text,
  forPracticeModal = false /*display = 'default' */,
}: Props) {
  const {
    generateAudioFromText,
    getLastGeneratedAudioForText,
    getAudioForHistoryItemId,
  } = useElevenLabs()
  const { play, pause, duration, setAudioUrl, audioUrl, isPlaying, progress } =
    useAudioPlayer()
  const [isLoading, setIsLoading] = useState(false)

  async function checkHistory() {
    const [item, error] = await getLastGeneratedAudioForText(text)

    if (error || !item) {
      setAudioUrl(null)
      return
    }

    const [audio, err] = await getAudioForHistoryItemId(item.history_item_id)

    if (err || !audio) {
      setAudioUrl(null)
      return
    }

    setAudioUrl(audio.url)
  }

  async function generateAudio() {
    setIsLoading(true)
    const [audio, error] = await generateAudioFromText(text)

    if (error || !audio) {
      setIsLoading(false)
      return
    }

    setAudioUrl(audio.url)
    setIsLoading(false)
  }

  function playAudio() {
    if (isPlaying) {
      pause()
    } else {
      play()
    }
  }

  async function onPlayAudio() {
    if (!audioUrl) {
      await generateAudio()
      return
    }

    playAudio()
  }

  useEffect(() => {
    checkHistory()
  }, [text])

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 relative items-center">
          <div className="rounded-full overflow-hidden w-9 h-9">
            <img
              src="/assets/arnold.png"
              alt="Arnold picture"
              className="w-full h-full"
            />
          </div>

          <div className="absolute top-[22px] left-[22px] w-4 h-4 border border-neutral-01 rounded-full">
            <img
              src="/assets/flag-usa.png"
              alt="USA flag"
            />
          </div>

          {!forPracticeModal && <span className="text-lg">says</span>}
        </div>
      </div>

      <button
        className={`p-2 rounded-2xl ${forPracticeModal ? 'bg-[#894EE2] w-fit' : 'bg-neutral-shades-04'} text-white flex mt-2 items-end`}
        onClick={onPlayAudio}
      >
        <Icon
          isPlaying={isPlaying}
          hasAudio={!!audioUrl}
          isLoading={isLoading}
        />

        <div className="flex flex-col justify-between items-start mx-2 w-10 h-9">
          <AudioProgress progress={progress} />
          <span className="text-white text-xs">{duration}</span>
        </div>
      </button>
    </>
  )
}

function Icon({
  isPlaying,
  hasAudio = true,
  isLoading = false,
}: {
  isPlaying: boolean
  hasAudio?: boolean
  isLoading?: boolean
}) {
  const sizes = {
    width: 36,
    height: 36,
  }

  if (isLoading) {
    return (
      <Lottie
        animationData={loader}
        loop={true}
        style={sizes}
      />
    )
  }

  if (!hasAudio) {
    return <DownloadIcon {...sizes} />
  }

  if (isPlaying) {
    return <PauseIcon {...sizes} />
  }

  return <PlayIcon {...sizes} />
}

export default ElevenlabsPlayer
