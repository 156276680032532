import { useComputed, useSignals } from '@preact/signals-react/runtime'
import { ratingResults } from './ratings'

export function IeltsTotal() {
  useSignals()

  const score = useComputed(() => {
    let total = 0

    for (const [, val] of Object.entries(ratingResults.value)) {
      total += parseInt(val?.level ?? '0')
    }

    return total / 4
  })

  if (!score.value || isNaN(score.value)) return <></>

  return (
    <div className="border-2 rounded-xl border-neutral-03 text-neutral-04 font-bold flex gap-2 px-4 py-2">
      <span className="text-sm leading-6 underline">IELTS Score</span>
      <span className="text-lg leading-6">{score}</span>
    </div>
  )
}
