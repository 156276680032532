import { useMemo } from 'react'
import Select from '@components/action/select'
import { useNavigate, getRouteApi } from '@tanstack/react-router'

type props = {
  path: string
  modes: {
    label: string
    value: string
  }[]
  onChange?: () => void
}

function HeaderSection({ path, modes, onChange = () => {} }: props) {
  const route = getRouteApi(path)
  const navigate = useNavigate()
  const { mode } = route.useSearch()

  const selectedMode = useMemo(() => {
    return modes.find((value) => value.value === mode) ?? modes[0]
  }, [mode])

  function onModeChange(value: string | number) {
    onChange()

    navigate({ to: path, search: { mode: value } })
  }

  return (
    <div className="mb-4 lg:mb-6">
      <div className="lg:w-72">
        <Select
          data={modes}
          selected={selectedMode}
          onChange={onModeChange}
        />
      </div>
    </div>
  )
}

export default HeaderSection
