import axios from 'axios'
import { notificationSig } from '@signals'
import { batch } from '@preact/signals-react'

export { http as connector } from './connector'
export * as questions from './openQuestions'

export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      batch(() => {
        notificationSig.message.value = 'Your are not connected to the internet. Check you connection and try again.'
        notificationSig.duration.value = null
        notificationSig.intent.value = 'error'
      })
    }

    if (error.response?.status === 403) {
      batch(() => {
        notificationSig.message.value = 'Your connection has been interrupted. Please reconnect.'
        notificationSig.duration.value = 5000
        notificationSig.intent.value = 'error'
      })
    }

    return Promise.reject(error)
  },
)

export function postAudio(body: FormData, isWord: boolean = false) {
  if (isWord) {
    return http.post('/performance-tool/analysis/word', body)
  }

  return http.post('/performance-tool/analysis/sentence', body)
}

export function postTranslateAudio(body: FormData) {
  return http.post('/openai/whisper', body)
}

export function postUnscriptedAudio(body: FormData) {
  return http.post('/performance-tool/analysis/open-question', body)
}

export function getAudios(content: string) {
  return http.get(`/performance-tool/recordings?content=${content}`)
}

export function practice(body: FormData) {
  return http.post('/performance-tool/analysis/practice', body)
}

export function ping() {
  return http.get('/performance-tool/ping')
}

export function rateResponse(body: { content: string; type: string; skill: string }) {
  return http.post('/performance-tool/ratings', body)
}
