function PlayIcon({ color = 'white', width = 32, height = 32 }: { color?: string; width?: number; height?: number }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Play/Pause">
        <path id="Vector" d="M13 20.3876L13 11.6112C13.0002 11.5014 13.0297 11.3936 13.0855 11.2993C13.1412 11.2049 13.2212 11.1273 13.317 11.0748C13.4128 11.0222 13.5208 10.9965 13.6299 11.0004C13.7389 11.0043 13.8449 11.0376 13.9367 11.0969L20.7199 15.4839C20.8058 15.5393 20.8764 15.6155 20.9254 15.7056C20.9743 15.7957 21 15.8967 21 15.9994C21 16.1021 20.9743 16.2031 20.9254 16.2932C20.8764 16.3832 20.8058 16.4595 20.7199 16.5149L13.9367 20.9031C13.8449 20.9624 13.7389 20.9957 13.6299 20.9996C13.5208 21.0035 13.4128 20.9778 13.317 20.9252C13.2212 20.8727 13.1412 20.7951 13.0855 20.7007C13.0297 20.6064 13.0002 20.4986 13 20.3888V20.3876Z" fill={color} />
        <path id="Vector_2" d="M1.3335 16C1.3335 7.90001 7.90016 1.33334 16.0002 1.33334C24.1002 1.33334 30.6668 7.90001 30.6668 16C30.6668 24.1 24.1002 30.6667 16.0002 30.6667C7.90016 30.6667 1.3335 24.1 1.3335 16ZM16.0002 3.33334C12.6408 3.33334 9.41894 4.66786 7.04348 7.04332C4.66802 9.41878 3.3335 12.6406 3.3335 16C3.3335 19.3594 4.66802 22.5812 7.04348 24.9567C9.41894 27.3322 12.6408 28.6667 16.0002 28.6667C19.3596 28.6667 22.5814 27.3322 24.9568 24.9567C27.3323 22.5812 28.6668 19.3594 28.6668 16C28.6668 12.6406 27.3323 9.41878 24.9568 7.04332C22.5814 4.66786 19.3596 3.33334 16.0002 3.33334Z" fill={color} />
        <path id="Vector_3" d="M24 19.8333C24 18.6417 22.9071 17.6667 21.5714 17.6667L9.42857 17.6667C8.09286 17.6667 7 18.6417 7 19.8333C7 21.025 8.09286 22 9.42857 22L21.5714 22C22.9071 22 24 21.025 24 19.8333ZM9.42857 13.3333L21.5714 13.3333C22.9071 13.3333 24 12.3583 24 11.1667C24 9.975 22.9071 9 21.5714 9L9.42857 9C8.09286 9 7 9.975 7 11.1667C7 12.3583 8.09286 13.3333 9.42857 13.3333Z" fill={color} fillOpacity="0.01" />
      </g>
    </svg>
  )
}

export default PlayIcon
