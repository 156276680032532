import { useMemo } from 'react'
import { useRouterState } from '@tanstack/react-router'
import { useSignals } from '@preact/signals-react/runtime'

import { analysisSig } from '@signals'
import { Feedback } from '@/types/feedback.d'
import Heading from '@components/ui/heading'
import { HourglassIcon } from '@icons'
import { Rating } from '@/types/ratings.d'

import Ratings, { selectedTile } from './components/ratings'
import { FeedbackList } from './components/feedbackList'
import { RatingList } from './components/ratingList'
import { IeltsTotal } from './components/ieltsTotal'

type props = {
  isLoading: boolean
  feedback: Feedback.ApiResponse | null
  ratingsConfig: Rating.Config[]
}

export function FeedbackSection(props: props) {
  const router = useRouterState()

  return (
    <div className="lg:max-h-screen lg:overflow-y-auto w-full">
      <div className="flex items-center gap-2">
        <Heading
          title="All Suggestions"
          squareClasses="bg-secondary-02"
        />

        {router.location.pathname.includes('/unscripted') && <IeltsTotal />}
      </div>

      <Content {...props} />
    </div>
  )
}

function Content({ isLoading, feedback, ratingsConfig }: props) {
  useSignals()

  const feedbackList: Feedback.ApiResponse | null = useMemo(() => {
    if (!feedback?.feedback) return null

    const res = feedback.feedback.map((f) => {
      f.feedback = f.feedback.sort((a, b) => {
        if (a.aspect === 'WORD_STRESS' && a.status === 'failure') return -1
        if (b.aspect === 'WORD_STRESS' && b.status === 'failure') return 1

        return 0
      })

      return f
    })

    return {
      ...feedback,
      feedback: res,
    } as Feedback.ApiResponse
  }, [feedback])

  if (analysisSig.error.value) {
    return <div className="border border-red-500 p-4 rounded-xl text-red-500 font-semibold mt-6">{analysisSig.error}</div>
  }

  if (isLoading) {
    return (
      <div className="bg-neutral-03 flex items-center justify-center gap-3 text-neutal-05 leading-6 px-4 py-2 rounded-xl w-fit mt-6">
        <HourglassIcon />
        Analyzing your voice...
      </div>
    )
  }

  if (!feedbackList) {
    return <p className="mt-4 bg-neutral-03 rounded-xl p-4 text-center text-neutral-05">No feedback available</p>
  }

  return (
    <div className="mt-4 flex flex-col gap-4">
      <Ratings
        feedback={feedbackList}
        config={ratingsConfig}
        content={feedbackList.metadata.baseContent}
      />

      {selectedTile.value === Rating.FEEDBACK_TYPE.PRONUNCIATION && <FeedbackList data={feedbackList.feedback} />}

      {selectedTile.value !== Rating.FEEDBACK_TYPE.PRONUNCIATION && <RatingList />}
    </div>
  )
}
