function AudioProgress({ progress = 0 }: { progress: number }) {
  return (
    <div className="flex-1 flex items-center w-full relative">
      <div className="w-full border border-black rounded-xl"></div>
      <div className="absolute left-0 top-1/2 -translate-y-1/2 h-1 bg-[#D9D9D9]" style={{ width: `${progress}%` }}></div>
      <div className="absolute top-1/2 -translate-y-1/2 w-2 h-2 rounded-full bg-[#D9D9D9]" style={{ left: `${progress}%` }}></div>
    </div>
  )
}

export default AudioProgress
