import { useMemo } from 'react'

type orientation = 'left' | 'right' | 'up' | 'down'

export default function Chevron({ direction = 'down', color = 'stroke-neutral-04' }: { direction?: orientation; color?: string }) {
  const classes = useMemo(() => {
    switch (direction) {
      case 'left':
        return 'rotate-90'
      case 'up':
        return 'rotate-180'
      case 'right':
        return '-rotate-90'
      case 'down':
      default:
        return ''
    }
  }, [direction])

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g id="Chevron">
        <path
          id="Vector 1"
          d="M6 9L12 15L18 9"
          className={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
