import { useEffect, useState, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import Avatar from './Avatar'
import { useAudioRecorder } from '@/hooks'
import { practice } from '@api'
import ElevenlabsPlayer from './ui/elevenlabs-player'
import { Feedback } from '@/types/feedback.d'
import Button from './action/button'
import { PlusIcon, MicIcon, BouncingDotsAnimation } from '@icons'

type Props = {
  visible: boolean
  word: Feedback.Word
  onClose: () => void
}

const MAX_PRACTICE_TRIES = 3
const MAX_MESSAGES = 3

function PracticeModal({ visible, word, onClose }: Props) {
  let timeoutId: number

  if (!visible) {
    return null
  }

  const [variant, setVariant] = useState(1)
  const [loadedMessage, setLoadedMessage] = useState(0)

  function update() {
    if (loadedMessage >= MAX_MESSAGES) return

    setVariant((variant) => ((variant + 1) % MAX_MESSAGES) + 1)
    setLoadedMessage((loadedMessage) => loadedMessage + 1)

    timeoutId = setTimeout(update, 3000)
  }

  useEffect(() => {
    timeoutId = setTimeout(update, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    setLoadedMessage(0)
  }, [visible])

  const mutation = useMutation({
    mutationFn: practice,
    onSuccess: (res) => {
      const newMessages = selectMessages(res.data.data, MAX_PRACTICE_TRIES - remainingAttempts)

      setMessages(newMessages)
      setRemainingAttempts((prevAttempts) => prevAttempts - 1)
      setLoading(false)
    },
    onError: (e: any) => {
      console.error(e)
      if (typeof e.response.data.data === 'string') {
        setMessages([e.response.data.data])
      }
      setLoading(false)
    },
  })

  const { start, stop, audioBlob, isRecording } = useAudioRecorder()

  const [remainingAttempts, setRemainingAttempts] = useState(MAX_PRACTICE_TRIES)
  const [svgStroke, setSvgStroke] = useState('stroke-neutral-01')
  const [blob, setBlob] = useState<Blob | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [messages, setMessages] = useState<(string | null)[]>([])

  useEffect(() => {
    if (!isRecording && audioBlob) {
      onSubmit()
    }
  }, [isRecording, audioBlob])

  function onSubmit() {
    setLoading(true)

    const blobToSend = audioBlob ?? blob

    if (!blobToSend) return

    const formData = new FormData()
    formData.append('audio', blobToSend)
    formData.append('content', word.word)
    formData.append('attempt', MAX_PRACTICE_TRIES - remainingAttempts + 1 + '')

    mutation.mutate(formData)
  }

  function onRecordingClick() {
    if (remainingAttempts === 0) return

    if (isRecording) {
      stop()
      return
    }

    start()
    setBlob(null)
  }

  const actionLabel = useMemo(() => {
    if (remainingAttempts === 0) return 'Continue practicing other words'
    if (isRecording) return 'Click once to stop recording'
    return 'Click once to record the word'
  }, [isRecording])

  const stressPattern = getStressPattern(word.feedback)
  const phonetics = word.ipa ?? 'Missing'

  const feedbackList = useMemo(() => {
    const last = loadedMessage >= MAX_MESSAGES ? MAX_MESSAGES : loadedMessage + 1

    return word.feedback
      .filter((f) => f.type !== Feedback.CardType.WORD_STRESS_VISUALIZATION)
      .filter((f) => f.status === 'failure')
      .sort((a, b) => {
        if (a.aspect === 'WORD_STRESS') return -1
        if (b.aspect === 'WORD_STRESS') return 1

        return b.priority - a.priority
      })
      .slice(0, last)
      .map((f) => f.message)
  }, [variant])

  const messagesList = useMemo(() => {
    if (messages.length > 0) {
      return messages
    }

    return feedbackList
  }, [feedbackList, messages])

  return (
    <div className="fixed top-0 left-0 w-full h-full px-4 flex items-center justify-center z-50 bg-neutral-07 bg-opacity-50">
      <div className="relative bg-white max-w-xl w-full p-6 rounded-2xl">
        <header>
          <h3 className="text-neutral-07 text-3xl underline font-bold">{word.word}</h3>
        </header>

        <div className="mt-3">
          <div className="flex flex-wrap gap-4 align-top">
            <ElevenlabsPlayer
              text={word.word}
              forPracticeModal
            />

            <div>
              <p className="text-sm text-neutral-04">Phonetics</p>
              <span>{phonetics}</span>
            </div>

            <div>
              <p className="text-sm text-neutral-04">Stress pattern</p>
              <div className="flex gap-1 items-center">
                {stressPattern.map((score, i) => (
                  <span
                    key={i}
                    className={`bg-purple-500 rounded-full ${score === 0 ? 'w-3 h-3' : 'w-5 h-5'}`}
                  ></span>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h4 className="text-sm leading-8 font-bold">How to improve</h4>

            <div className="mt-1">
              <MessagesSection
                messages={messagesList}
                isLoading={isLoading}
                variant={variant}
              />
            </div>
          </div>
        </div>

        <footer className="mt-5 border-t">
          <div className="p-4 flex justify-between items-center">
            <div className="text-xs leading-4">
              <span className="font-bold">{remainingAttempts}</span>
              <span> attempt(s) left</span>
            </div>

            <Button
              onMouseEnter={() => setSvgStroke('stroke-neutral-07')}
              onMouseLeave={() => setSvgStroke('stroke-neutral-01')}
              onClick={onRecordingClick}
              disabled={remainingAttempts === 0 || isLoading}
            >
              <MicIcon stroke={svgStroke} />
              {actionLabel}
            </Button>
          </div>
        </footer>

        <button
          onClick={onClose}
          className="absolute right-0 bottom-full mb-2 lg:top-0 lg:left-full lg:ml-2 rounded-full w-9 h-9 bg-white flex items-center justify-center"
        >
          <PlusIcon asClose />
        </button>
      </div>
    </div>
  )
}

function MessagesSection({ messages, isLoading, variant }: { messages: (string | null)[]; isLoading: boolean; variant: number }) {
  if (isLoading) {
    return (
      <div className="flex gap-2">
        <Avatar variant={0} />

        <div className="flex-1">
          <BouncingDotsAnimation />
        </div>
      </div>
    )
  }

  return (
    <div className="flex gap-2">
      <Avatar variant={variant} />

      <ul className="flex-1">
        {messages.map((message, i) => (
          <li
            key={i}
            className="peer feedback-message peer-[.feedback-message]:border-t py-1 text-xs leading-6"
          >
            {message}
          </li>
        ))}
      </ul>
    </div>
  )
}

function getStressPattern(cards: Feedback.Card[]) {
  const card = cards.find((c) => c.type === Feedback.CardType.WORD_STRESS_VISUALIZATION)

  if (!(typeof card?.metadata?.expectedPattern === 'string')) {
    return []
  }

  return card.metadata.expectedPattern.split('').map(Number)
}

function selectMessages(feedback: Feedback.ApiResponse, attempt: number): (string | null)[] {
  const res: (string | null)[] = []
  const word = feedback.feedback[0]

  if (feedback.practiceMessage) res.push(feedback.practiceMessage)

  if (attempt === MAX_PRACTICE_TRIES) return res

  if (attempt === 0) {
    const m = word.feedback
      .filter((f) => f.status === 'success')
      .slice(0, MAX_MESSAGES - res.length)
      .map((f) => f.message)

    return [...res, ...m]
  }

  if (attempt === 1) {
    const m = word.feedback
      .filter((f) => f.status === word.status)
      .slice(0, MAX_MESSAGES - res.length)
      .map((f) => f.message)

    return [...res, ...m]
  }

  return res
}

export default PracticeModal
