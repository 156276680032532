import { useMemo, useState } from 'react'

import { Feedback } from '@/types/feedback'
import Button from '@components/action/button'

import Card from './card'

type Props = {
  data: Feedback.Word[]
  tags?: string[]
  revealStep?: number
  showPractice?: boolean
}

export function FeedbackList({ data, tags = [], revealStep = 4, showPractice = true }: Props) {
  const [visibleCards, setVisibleCards] = useState(revealStep)

  const hasMoreFeedback = useMemo(() => {
    return visibleCards < data.length
  }, [data, visibleCards])

  const moreFeedbackLabel = useMemo(() => {
    if (!hasMoreFeedback) return 'No more feedback available'

    return 'Show more feedback'
  }, [])

  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3)
  }

  return (
    <>
      {data.slice(0, visibleCards).map((word, i) => {
        let tagList = [
          {
            slug: 'score',
            text: `${(word.score.score * 100).toFixed()}%`,
            color: getTagColor(word.score.category),
            visible: true,
          },
          {
            slug: 'category',
            text: word.score.category,
            color: getTagColor(word.score.category),
            visible: true,
          },
          {
            slug: 'mistakes',
            text: `${getMistakesCount(word.feedback)} mistake(s)`,
            color: 'red' as 'green' | 'red' | 'orange',
            visible: getMistakesCount(word.feedback) > 0,
          },
        ]

        if (tags.length) {
          tagList = tagList.filter((t) => !!tags.includes(t.slug))
        }

        return (
          <Card
            key={`feedback_card_${i}`}
            title={word.word}
            description={getFeedbackMessage(word.feedback, word.status)}
            status={word.status}
            tags={tagList}
            metadata={word}
            word={showPractice ? word : undefined}
          />
        )
      })}

      {data.length > revealStep && (
        <div className="mt-12 mx-auto">
          <Button
            onClick={loadMoreCards}
            text={moreFeedbackLabel}
            disabled={!hasMoreFeedback}
          />
        </div>
      )}
    </>
  )
}

function getFeedbackMessage(feedback: Feedback.Card[], status: 'success' | 'failure'): string {
  const res = feedback.find((f) => f.status === status && f.message)

  return res?.message ?? feedback[0].message ?? ''
}

function getMistakesCount(feedback: Feedback.Card[]): number {
  return feedback.reduce((acc, cur) => {
    if (cur.status === 'failure') {
      return acc + 1
    }

    return acc
  }, 0)
}

function getTagColor(category: string): 'green' | 'red' | 'orange' {
  switch (category.toLowerCase()) {
    case 'needs work':
      return 'red'

    case 'good':
      return 'orange'

    case 'excellent':
    default:
      return 'green'
  }
}
