export namespace Feedback {
  export type ApiResponse = {
    status: 'success' | 'failure'
    feedback: Word[]
    metadata: {
      audioPath: string
      baseContent: string
    }
    practiceMessage?: string
  }

  export type Word = {
    id: string
    word: string
    wordIndex: number
    ipa?: string
    feedback: Card[]
    metadata: Record<string, unknown>
    status: 'success' | 'failure'
    feedbackPriorities: {
      success: number
      failure: number
      total: number
    }
    score: {
      score: number
      category: string
    }
  }

  export type Card = {
    order: number | null
    type: CardType
    status: 'success' | 'failure'
    message: string | null
    image: string | null
    priority: number
    metadata: Record<string, unknown>
    aspect: string
  }

  export enum CardType {
    EXPANDAPLE_WITH_AUDIO = 'EXPANDAPLE_WITH_AUDIO',
    GIF = 'GIF',
    AUDIO_AND_TEXT = 'AUDIO_AND_TEXT',
    RULES_WITH_ONLY_TEXT = 'RULES_WITH_ONLY_TEXT',
    RULES_WITH_EXAMPLES = 'RULES_WITH_EXAMPLES',
    TEXT_AND_AVATAR = 'TEXT_AND_AVATAR',
    AUDIO_ISSUE = 'AUDIO_ISSUE',
    REPORT_PROBLEM = 'REPORT_PROBLEM',
    TRY_AGAIN = 'TRY_AGAIN',
    WORD_STRESS_VISUALIZATION = 'WORD_STRESS_VISUALIZATION',
    SENTENCE_STRESS_VISUALIZATION = 'SENTENCE_STRESS_VISUALIZATION',
    FOCUS_WORDS_VISUALIZATION = 'FOCUS_WORDS_VISUALIZATION',
  }
}
